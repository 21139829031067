import React from 'react';
import {
  Space,
  Table,
  Tag,
  Tabs,
  Divider,
  Button,
  Form,
  Input,
  Row,
  Col,
} from 'antd';
import { WarningOutlined } from '@ant-design/icons';
const LoginBgImage = require('../../images/login-bg.jpeg');
export default function MainEX() {
  return (
    <>
      <div
        style={{
          fontSize: '20px',
          marginTop: '40px',
          marginLeft: '40px',
          display: 'block',
        }}>
        <a href={process.env.REACT_APP_POWER_BI_URL}>個人化廣宣成效追蹤</a>
        <p style={{ color: 'red', fontSize: '14px' }}>
          <WarningOutlined />
          若無權限請填資訊服務申請單申請，資訊服務需求選項：11數據資料分析及調閱>04其他
        </p>
      </div>
      <div className="cover">
        <img
          src={LoginBgImage}
          alt="BigCo Inc. logo"
          width={'100%'}
          height={'100%'}
        />
      </div>
    </>
  );
}
